@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Cairo", sans-serif;
  overflow: scroll;
  background-image: linear-gradient(to bottom right, white, #d7e1ec);
  background-size: cover;
}

a {
  color: #ff9849;
}

a:hover {
  color: #ff9849;
  text-decoration: none;
}

* {
  font-family: "Cairo", sans-serif;
}

main {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: linear-gradient(to bottom right, white, #d7e1ec);
  background-size: cover;
}

/*--------------------------------------------------------------
# NavBar
--------------------------------------------------------------*/
.navbar {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.navbar .nav-link {
  color: grey;
  font-size: 16px;
  font-weight: bold;
}

.navbar .nav-link:hover,
.navbar .nav-link:focus {
  color: #ff9849;
  text-decoration: none;
}

/* Change navbar styling on scroll */
.navbar.active {
  background: #fff;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar.active .nav-link {
  color: grey;
  font-size: 16px;
}

.navbar.active .nav-link:hover,
.navbar.active .nav-link:focus {
  color: #ff9849;
  text-decoration: none;
}

.brand {
  width: 100px;
  height: 80px;
}

.profilePic {
  height: 30px;
  width: 30px;
}

.signout {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.signout:hover {
  color: #ff9849;
}

.delete {
  color: red;
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.delete:hover {
  color: #ff9849;
}

.signIn {
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
  border-style: none;
  flex: 1 1 auto;
  padding: 5px 10px 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 30px;
}

.fa-heart {
  font-size: 16px;
}

.signIn:hover {
  background-position: right center;
}

/* ===========================
  Landing Page
==============================*/
#landing {
  padding-top: 120px;
  height: 400px;
}

#landing h1 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  color: grey;
}

#landing h1 span {
  color: #ff9849;
  border-bottom: 4px solid #ff9849;
}

#landing h2 {
  color: grey;
  margin-bottom: 40px;
  font-size: 24px;
}

#landing .btn-get-started {
  background-image: linear-gradient(to right, #fff 0%, #fff 51%, #eaeaea 100%);
  flex: 1 1 auto;
  padding: 12px 20px 12px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  box-shadow: 0 0 20px #eee;
  border-radius: 30px;
  font-size: 100%;
}

#landing .btn-get-started:hover {
  background-position: right center;
}

#landing iframe {
  width: 100%;
  height: 100%;
}

.side-contact-whats {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  text-align: center;
  bottom: 200px;
  right: 20px;
  background: green;
  color: #fff;
  font-size: 24px;
  z-index: 999;
  transition: all 0.3s linear;
}

.side-contact-whats i {
  margin-top: 12px;
}

.side-contact-whats:hover {
  color: #ff9849;
}

.side-contact-phone {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  text-align: center;
  bottom: 130px;
  right: 20px;
  background: #00aeff;
  color: #fff;
  font-size: 24px;
  z-index: 999;
  transition: all 0.3s linear;
}

.side-contact-phone i {
  margin-top: 12px;
}

.side-contact-phone:hover {
  color: #ff9849;
}

#team h1 {
  font-weight: 400px;
  font-size: 3rem;
  margin-bottom: 100px;
}

#team b {
  color: #ff9849;
}

#services-container {
  margin-top: 200px;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 30px;
  padding: 0px;
  align-items: stretch;
  list-style-type: none;
}

.services-list .card {
  cursor: pointer;
  height: 250px;
  border-width: 0px;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 30px;
  text-decoration: none;
}

.services-list .card-body {
  padding: 0px;
}

.services-list .card:hover {
  box-shadow: 0px 0px 12px 2px wheat;
}

.services-list .card img {
  height: 60px;
  width: 60px;
  object-fit: contain;
  align-items: center;
  z-index: 2;
  transition: all 0.5s ease-out;
}

.services-list .card:hover img,
.services-list .card:hover .circle {
  transform: rotateY(360deg);
}

.services-list .circle {
  width: 4rem;
  height: 4rem;
  background: linear-gradient(to right, rgba(245, 222, 179, 0.548), rgba(255, 220, 193, 0.75));
  position: absolute;
  border-radius: 20%;
  transition: all 0.5s ease-out;
}

.services-list h5 {
  font-weight: bold;
  font-size: 100%;
  transition: all 0.75s ease-out;
  margin: 30px 0px 15px 0px;
}

.services-list p {
  font-size: 100%;
}

.testimonial:after {
  position: absolute;
  top: -0 !important;
  left: 0;
  content: " ";
  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}

.testimonial {
  min-height: 375px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: center;
  background-size: cover;
}
#testimonial4 .carousel-inner:hover {
  cursor: -webkit-grab;
}
#testimonial4 .carousel-inner:active {
  cursor: -webkit-grabbing;
}
#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #ff9849;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #000000;
  text-transform: uppercase;
  border-radius: 100%;
  box-shadow: none;
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}
.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}
.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}
.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}
.testimonial4_header h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}
.testimonial4_slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 200px;
  height: 200px;
  margin: auto;
  display: block;
  color: #f2f2f2;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}
.testimonial4_slide p {
  color: #fff;
  font-size: 20px;
  line-height: 1.4;
  margin: 40px 0 20px 0;
}
.testimonial4_slide h4 {
  color: #ff9849;
  font-size: 22px;
}

.testimonial4_slide h5 {
  color: grey;
}

.testimonial .carousel {
  padding-bottom: 50px;
}
.testimonial .carousel-control-next-icon,
.testimonial .carousel-control-prev-icon {
  -webkit-filter: invert(100%);
          filter: invert(100%);
  width: 35px;
  height: 35px;
}

.blockquote-custom {
  position: relative;
  font-size: 1.1rem;
}

.blockquote-custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
  left: 50px;
}

/* ===========================
  About us Page
==============================*/
input {
  border-style: none;
  border-radius: 10px;
  height: 50px;
  transition: all 0.4s ease-in;
}

input:focus {
  box-shadow: 0 0 10px#FF9849;
  outline: none;
}

form select {
  border-style: none;
  border-radius: 10px;
  height: 50px;
  transition: all 0.4s ease-in;
}

form select:focus {
  box-shadow: 0 0 10px#FF9849;
  outline: none;
}

form textarea {
  border-style: none;
  border-radius: 10px;
  transition: all 0.4s ease-in;
}

form textarea:focus {
  box-shadow: 0 0 10px#FF9849;
  outline: none;
}

form button {
  border-style: none;
  border-radius: 10px;
  height: 40px;
  color: white;
  background-color: #ff9849;
  transition: all 0.4s ease-in;
}

form button:hover {
  color: #1d2026;
  background-color: white;
  outline: none;
}

.list {
  list-style: none;
}

.list li {
  color: #ff9849;
  font-size: 20px;
}

/* ===========================
  Projects Page
==============================*/
.proj {
  align-items: stretch;
  list-style-type: none;
  padding: 0px;
}

.proj .card {
  transform-style: preserve-3d;
  cursor: pointer;
  height: auto;
  margin-top: 20px;
  border-width: 0px;
  transition: 0.2s ease-in;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 30px;
}

.proj .card img {
  height: 250px;
  width: 250px;
  z-index: 2;
  transition: all 0.75s ease-out;
}

.proj h5 {
  font-size: 2rem;
  transition: all 0.75s ease-out;
}

.proj .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #000;
  border: 2px solid #000;
  background: #fff;
}

.proj .btn-get-started:hover {
  color: #fff;
  border: 2px solid #ff9849;
  background: #ff9849;
}

/* ===========================
  Services Page
==============================*/
.num-skills {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 50px;
  padding: 0px;
  align-items: stretch;
  list-style-type: none;
}

.num-skills .card {
  cursor: pointer;
  height: 320px;
  border-width: 0px;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 30px;
  text-decoration: none;
}

.num-skills .card-body {
  padding: 0px;
}

.num-skills .card:hover {
  box-shadow: 0px 0px 12px 2px wheat;
}

.num-skills .card img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  align-items: center;
  z-index: 2;
  transition: all 0.5s ease-out;
}

.num-skills .card:hover img,
.num-skills .card:hover .circle {
  transform: rotateY(360deg);
}

.num-skills .circle {
  width: 6rem;
  height: 6rem;
  background: linear-gradient(to right, rgba(245, 222, 179, 0.548), rgba(255, 220, 193, 0.75));
  position: absolute;
  border-radius: 20%;
  transition: all 0.5s ease-out;
}

.num-skills h5 {
  font-weight: bold;
  transition: all 0.75s ease-out;
  margin: 10px 0px 15px 0px;
}

.num-skills p {
  font-size: 100%;
}

.title-service {
  align-self: center;
  border-style: solid;
  border-color: black;
  box-shadow: 5px 10px wheat;
  margin: 10%;
  padding: 10px;
}

.web-text {
  background-color: rgba(245, 222, 179, 0.548);
  padding: 10px;
}

.steps {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 20px;
  padding: 0px;
  align-items: stretch;
  list-style-type: none;
  list-style: none;
}

.steps .card {
  height: 200px;
  border-style: none;
  transition: ease-in-out 0.3s;
  margin: 10px;
  border-radius: 20px;
}

.steps .card:hover {
  box-shadow: 0px 0px 12px 2px wheat;
}

.steps .card .number {
  margin: 20px;
  background-color: rgba(245, 222, 179, 0.548);
  border-radius: 50%;
  padding: 5px;
  align-self: center;
  width: 50px;
  height: 50px;
}

.steps .card img {
  align-self: center;
  width: 100px;
  margin-top: 20px;
  height: 100px;
}

.steps .card h5 {
  font-size: 100%;
  text-align: center;
}

.steps .card p {
  height: 120px;
  text-align: center;
}

.card a:hover {
  color: black;
  background-color: white;
}

/* ===========================
  Login & Reset Password Page
==============================*/
.error {
  word-wrap: break-word;
}

/* ===========================
  Admin
==============================*/
.employeePic {
  width: 50px;
  height: 50px;
}

.employeeImg {
  width: 100px;
  height: 100px;
}

/* ===========================
  Employee
==============================*/
.picture {
  width: 150px;
  height: 150px;
}

/* ===========================
  Footer
==============================*/
.footer-logo .img-fluid {
  height: 100;
  width: 100;
}

footer ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

footer ul.social-network li {
  display: inline;
  margin: 0 5px;
}

.social-network .icoFacebook:hover {
  background-color: #3b5998;
}

.social-network .icoLinkedin:hover {
  background-color: #007bb7;
}

.social-network .icoTwitter:hover {
  background-color: #2295e2;
}

.social-network .icoInstagram:hover {
  background-color: red;
}

.social-network .icoWhatsapp:hover {
  background-color: green;
}

.social-network .icoFacebook:hover i,
.social-network .icoTwitter:hover i,
.social-network .icoInstagram:hover i,
.social-network .icoEmail:hover i,
.social-network .icoWhatsapp:hover i,
.social-network .icoLinkedin:hover i {
  color: #fff;
}

.social-network .socialIcon:hover,
.socialHoverClass {
  color: #44bcdd;
}

.social-circle li .icon {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle li .icon:hover i,
.triggeredHover {
  cursor: pointer;
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 0.2s;
}

.social-circle i {
  color: #595959;
  transition: all 0.8s;
}

.social-network .icon {
  background-color: #dee6ef;
}

.back-to-top {
  position: relative;
  z-index: 2;
}
.back-to-top .btn-dark {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ff9849;
  border-color: #ff9849;
  display: none;
  z-index: 999;
  transition: all 0.3s linear;
}

.back-to-top .btn-dark:hover {
  cursor: pointer;
  background: grey;
  border-color: grey;
}

.contact-form {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-form .icon:hover i,
.triggeredHover {
  cursor: pointer;
  background-color: #ff9849;
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 0.2s;
}

.contact-form .icon i {
  padding: 10px;
  background-color: #595959;
  color: #fff;
  border-radius: 50%;
}

/*--------------------------------------------------------------
# Chat Ui
--------------------------------------------------------------*/
body {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main {
  background-color: #eee;
  position: relative;
  border-radius: 8px;
  padding: 6px 0px 0px 0px;
}

.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 425px;
}

.img1 {
  border-radius: 50%;
  background-color: #66bb6a;
}

.name {
  font-size: 8px;
}

.msg {
  background-color: #fff;
  font-size: 11px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  color: #3e3c3c;
}

.fileMsg {
  cursor: pointer;
  font-size: 30px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.between {
  font-size: 8px;
  font-weight: 500;
  color: #a09e9e;
}

.navbar {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form-control {
  font-size: 12px;
  font-weight: 400;
  width: 230px;
  height: 30px;
  border: none;
}

form-control:focus {
  box-shadow: none;
  overflow: hidden;
  border: none;
}

.form-control:focus {
  box-shadow: none !important;
}

.icon1 {
  color: #7c4dff !important;
  font-size: 25px !important;
  padding-top: 8px;
  cursor: pointer;
}

.icon2 {
  color: #512da8 !important;
  font-size: 25px !important;
  margin-left: 8px;
  cursor: pointer;
}

/*--------------------------------------------------------------
# Resposive Section
--------------------------------------------------------------*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contact-form {
    width: 75%;
  }
  .navbar {
    background-color: #fff;
  }

  .navbar ul {
    transform: rotate(180deg);
  }

  .navbar ul > li {
    transform: rotate(-180deg);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/*--------------------------------------------------------------
# keyframes Section
--------------------------------------------------------------*/

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes animborder {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(113px);
  }
}

